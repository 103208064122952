import phoneGray from "../../../../assets/images/attachment/phoneGray.svg";
import React, { useState } from "react";
import { PayloadMessageContainer } from "../Message";
import { DataRow } from "./Order";

import PlayBtn from "../../../../assets/images/play-button.png";

const CallRecord = ({ message }) => {
    const [showPlayer, setShowPlayer] = useState(false)
    return <PayloadMessageContainer message={ message }
        meta={ { icon: phoneGray, title: 'Звонок из АТС' } }
    >
        <div className={ 'flex flex-col gap-2' }>
            <DataRow label={ 'Внутренний номер' } value={ message.innerPhone } />
            { showPlayer ?
                <audio preload={ 'auto' } autobuffer={ "true" } controls className={ 'bg-transparent' }>
                    <source src={ message.source } />
                </audio>
                : <a onClick={ () => setShowPlayer(true) }><img src={ PlayBtn } className="playIcon" alt="Играть" /></a>
            }
        </div>
    </PayloadMessageContainer>
}

export default CallRecord
